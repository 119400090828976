







































import Vue from 'vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import { mapActions, mapMutations, mapState } from 'vuex'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  name: 'ModalEditCategories',

  components: {
    SlickList,
    SlickItem
  },

  data () {
    return {
      sortedItem: null,
      sortingClassname: 'sorting-items',
      localCategories: [],
      beforeOrderCategories: []
    }
  },

  computed: {
    ...mapState('catalog', ['catalog', 'catalogCategories'])
  },

  watch: {
    catalogCategories: {
      deep: true,
      handler (val) {
        this.localCategories = cloneDeep(val)
      }
    }
  },

  methods: {
    ...mapActions('catalog', ['updateCategoriesPosition']),
    ...mapMutations('catalog', ['sortCategories']),

    onModalShown () {
      this.localCategories = cloneDeep(this.catalogCategories)
    },

    shouldCancelStart (e) {
      return !e.target.classList.contains('categories__drag-icon')
    },

    onModalHidden () {
      this.localCategories = []
    },

    onSortStart (event) {
      this.beforeOrderChildCategories = cloneDeep(this.catalogCategories)
      document.documentElement.classList.add(this.sortingClassname)
      this.sortedItem = this.catalogCategories[event.index]
    },

    onSortend () {
      document.documentElement.classList.remove(this.sortingClassname)
    },

    async onSorted (newSorteredList) {
      const sortedItemIndex = this.catalogCategories.findIndex((item) => item.id === this.sortedItem.id)
      let newIndex = 0

      // Если сортируемый элемент не поставили в начало (0 index),
      // то задаем новый индекс
      if (newSorteredList[0].id !== this.sortedItem.id) {
        newIndex = newSorteredList.findIndex(item => item.id === this.catalogCategories[sortedItemIndex].id)
      }

      // Если новый индекс совпадает со старым, то ничего не делаем
      if (sortedItemIndex === newIndex) return
      const payload = {
        newList: newSorteredList,
        catalogId: this.catalog.id,
        categoryInsertId: this.sortedItem.id, // category_id_to_insert
        categoryAfterInsertId: newIndex === 0 ? null : newSorteredList[newIndex - 1].id // category_id_after_which_insert
      }

      this.sortCategories(payload)

      try {
        await this.updateCategoriesPosition(payload)
        this.beforeOrderChildCategories = cloneDeep(this.catalogCategories)
      } catch {
        this.$toast.error('Произошла ошибка при сортировке')
        this.sortCategories({ newList: this.beforeOrderChildCategories })
      }
    }
  }
})
