





























































































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import Search from '@/components/Search/Search.vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'ModalCopyCatalog',

  components: {
    Loading,
    Search
  },

  data () {
    return {
      selectedSearchMethod: 'username',
      searchPlaceholder: 'Поиск по логину',
      loading: false,
      isCopyLoading: false,
      limit: 10,
      offset: 0,
      page: 1,
      step: 1,
      chosenProfile: null,
      activeIdx: null,
      searchValue: '',
      catalogToCopy: null,
      profilesFields: [
        {
          key: 'created_at',
          label: 'Дата создания УЗ'
        },
        {
          key: 'username',
          label: 'Логин'
        },
        {
          key: 'name',
          label: 'Название бизнеса'
        },
        {
          key: 'phone',
          label: 'Основной телефон'
        },
        {
          key: 'profile_data.location.address',
          label: 'Локация бизнеса'
        }
      ]
    }
  },

  watch: {
    selectedSearchMethod (val) {
      switch (val) {
        case 'phone':
          this.searchPlaceholder = 'Поиск по номеру телефона'
          break
        case 'username':
          this.searchPlaceholder = 'Поиск по логину'
          break
        default:
          break
      }

      if (!this.searchValue.trim()) return
      this.getProfilesPage()
    }
  },

  computed: {
    ...mapState('profiles', ['businessProfilesForCopyCatalog', 'businessProfilesForCopyCatalogCount']),
    ...mapState('catalog', ['catalogsForCopy'])
  },

  methods: {
    ...mapActions({
      getProfiles: 'profiles/getBusinessProfilesForCopyCatalog',
      getCatalogsForCopy: 'catalog/getCatalogsForCopy',
      getCatalog: 'catalog/getCatalog',
      getCatalogCategories: 'catalog/getCatalogCategories',
      copyCatalog: 'catalog/copyCatalog'
    }),

    async onModalShown () {
      if (!this.businessProfilesForCopyCatalog.length) {
        this.loading = true
        await this.getProfiles()
        this.loading = false
      }
    },

    async onModalHidden () {
      this.step = 1
      this.activeIdx = null
      this.catalogToCopy = null
    },

    async onClickProfile (profile: any) {
      this.step = 2
      this.chosenProfile = profile.profile_data.id
      this.loading = true
      await this.getCatalogsForCopy({
        businessProfileId: profile.profile_data.id
      })
      this.loading = false
    },

    onClickPrevious () {
      this.step = 1
      this.activeIdx = null
      this.catalogToCopy = null
    },

    changeTab (tabNumber) {
      this.page = tabNumber
      this.offset = (this.page - 1) * this.limit
    },

    onClickChooseCatalog (newCatalogId, catalogIdx) {
      this.activeIdx = catalogIdx
      this.catalogToCopy = newCatalogId
    },

    async onClickCopyCatalog () {
      try {
        this.isCopyLoading = true
        await this.copyCatalog({
          oldCatalogId: this.catalogToCopy,
          newCatalogId: this.$route.params.catalogId
        })
        await this.getCatalog({
          profileId: this.$route.params.profileId,
          catalogId: this.$route.params.catalogId
        })
        await this.getCatalogCategories({
          catalogId: this.$route.params.catalogId
        })
        this.$bvModal.hide('modal-copy-catalog')
      } catch (error) {
        if (error.response.status === 404) {
          this.$toast.error('В выбранном каталоге нет товаров')
        } else {
          this.$toast.error(error.message)
        }
      } finally {
        this.isCopyLoading = false
      }
    },

    async handleSearch () {
      this.loading = true
      await this.getProfiles({
        limit: 100,
        offset: 0,
        search_text: this.searchValue,
        include_hidden_from_search: true
      })
      this.loading = false
      // if (!this.searchValue.trim()) {
      // this.$refs['search-input'].$el.focus()
      // return
      // }

      // this.offset = 0
    },

    async clearSearchValue () {
      this.searchValue = ''
      this.loading = true
      await this.getProfiles({ limit: 100, offset: 0, search_text: this.searchValue })
      this.loading = false
      // this.offset = 0

      // await this.getProfilesPage()
    }
  }
})
