






































































import Vue from 'vue'
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import CatalogChildCategories from '@/components/Catalogs/CatalogChildCategories.vue'
import CatalogCategories from '@/components/Catalogs/CatalogCategories.vue'
import CatalogProducts from '@/components/Catalogs/CatalogProducts.vue'
import ModalEditChildCategories from '@/components/Catalogs/ModalEditChildCategories.vue'
import ModalEditCategories from '@/components/Catalogs/ModalEditCategories.vue'
import ModalEditProduct from '@/components/Catalogs/ModalEditProduct.vue'
import ModalEditCategory from '@/components/Catalogs/ModalEditCategory.vue'
import ModalEditChildCategory from '@/components/Catalogs/ModalEditChildCategory.vue'
import ModalEditCatalog from '@/components/Catalogs/ModalEditCatalog.vue'
import Add from '@/components/Catalogs/Add.vue'
import Search from '@/components/Search/Search.vue'
import ModalCopyCatalog from '@/components/Catalogs/ModalCopyCatalog.vue'

export default Vue.extend({
  name: 'Catalog',

  components: {
    ModalEditProduct,
    CatalogChildCategories,
    CatalogProducts,
    CatalogCategories,
    Add,
    ModalEditChildCategories,
    ModalEditCategories,
    ModalEditCategory,
    ModalEditChildCategory,
    ModalEditCatalog,
    Search,
    ModalCopyCatalog
  },

  data () {
    return {
      searchValue: '',
      isSearchActive: false
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'catalogCategories',
      'selectedCatalogCategory',
      'selectedCatalogChildCategory'
    ]),

    actionsButtons () {
      return [
        {
          label: 'Создать товар',
          action: 'add-product'
        },
        {
          label: 'Создать категорию',
          action: 'add-child-category'
        },
        {
          label: 'Создать раздел',
          action: 'add-category'
        },
        {
          label: 'Создать каталог',
          action: 'add-catalog'
        }
      ]
    },

    ...mapState('profiles', [
      'profile'
    ])

    // breadcrumbItems () {
    //   return [
    //     {
    //       text: 'Активные бп',
    //       to: { name: 'profiles-active' }
    //     },
    //     {
    //       text: this.profile.username,
    //       to: { name: 'profile-main', params: { profileId: this.$route.params.profileId } }
    //     },
    //     {
    //       text: 'Каталоги',
    //       to: { name: 'profile-catalogs', params: { profileId: this.$route.params.profileId } }
    //     },
    //     {
    //       text: this.catalog.name,
    //       active: true
    //     }
    //   ]
    // }
  },

  watch: {
    selectedCatalogCategory (val) {
      if (!val.id) return

      // this.setCatalogChildCategoryProducts([])
      // this.setSelectedCatalogChildCategory({})
      this.fetchCatalogChildCategories()
    },

    searchValue (value) {
      if (!value) this.clearSearchValue()
    }
  },

  async created () {
    await this.fetchCatalog()
    await this.fetchCatalogCategories()
    // this.setBreadcrumbItems(this.breadcrumbItems)
  },

  beforeDestroy () {
    this.setCatalogChildCategoryProducts([])
    this.setCatalogChildCategories([])
    this.setCatalogCategories([])
    this.setSelectedCatalogCategory({})
    this.setSelectedCatalogChildCategory({})
  },

  methods: {
    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapMutations('catalog', [
      'setCatalogChildCategoryProducts',
      'setCatalogChildCategories',
      'setCatalogCategories',
      'setSelectedCatalogCategory',
      'setSelectedCatalogChildCategory'
    ]),

    // ...mapActions('common', [
    //   'setBreadcrumbItems'
    // ]),

    ...mapActions('catalog', [
      'getCatalog',
      'getCatalogCategories',
      'getCatalogChildCategories',
      'searchCatalogProducts'
    ]),

    async fetchCatalog () {
      try {
        this.isSearchActive = false
        this.searchValue = ''
        this.setLoading(true)
        await this.getCatalog({
          profileId: this.profile.profile_data.id,
          catalogId: this.$route.params.catalogId
        })
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    async fetchCatalogCategories () {
      try {
        this.isSearchActive = false
        this.searchValue = ''
        this.setLoading(true)
        await this.getCatalogCategories({
          catalogId: this.$route.params.catalogId
        })
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    async fetchCatalogChildCategories () {
      try {
        this.isSearchActive = false
        this.searchValue = ''
        this.setLoading(true)
        await this.getCatalogChildCategories()
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    // async fetchCatalogContent () {
    //   try {
    //     this.setLoading(true)
    //     await this.getCatalogContent({
    //       profileId: this.profile.profile_data.id,
    //       catalogId: this.$route.params.catalogId
    //     })
    //   } catch (error) {
    //     console.info(error)
    //     this.$toast.error(error.message)
    //   } finally {
    //     this.setLoading(false)
    //   }
    // },

    handleAddProduct () {
      this.$bvModal.show('modal-edit-product')
    },

    handleAddCategory () {
      this.$bvModal.show('modal-edit-catalog-category')
    },

    handleAddChildCategory () {
      this.$bvModal.show('modal-edit-catalog-child-category')
    },

    handleAddCatalog () {
      this.$bvModal.show('modal-edit-catalog')
    },

    async handleSearch () {
      await this.searchCatalogProducts({
        profileId: this.catalog.business_profile_id,
        catalogId: this.catalog.id,
        searchField: this.searchValue
      })
      this.isSearchActive = true
    },

    clearSearchValue () {
      this.isSearchActive = false
      this.searchValue = ''
    },

    onSaveProductForm () {
      this.clearSearchValue()
    },

    showModalCopyCatalog () {
      this.$bvModal.show('modal-copy-catalog')
    }
  }
})
